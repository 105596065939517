import axios from "axios";
import { API_MOCK_URL, API_URL } from "@/constant/api";

/**
 * 是否开发环境
 */
export const isDev = process.env.NODE_ENV === "development";

const myAxios = axios.create({
  baseURL: isDev ? API_MOCK_URL : API_URL,
  timeout: 100000,
  withCredentials: true,
});
// 添加请求拦截器
myAxios.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
myAxios.interceptors.response.use(
  function (response) {
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么
    return response;
  },
  function (error) {
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);
export default myAxios;
