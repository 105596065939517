/**
 * 权限定义
 */
const ACCESS_ENUM = {
  NOT_LOGIN: "NOT_LOGIN",
  USER: "user",
  VIP: "vip",
  ADMIN: "admin",
};
export default ACCESS_ENUM;
